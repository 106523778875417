<template>
  <div class="accountset enterpriselist">
    <userheader menucurrent="2" />
    <div class="main-div">
      <div class="account-content wrap">
        <userleft currindex="5" />
        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="titleL">
                我的简历
                <em></em>
              </h3>
              <div class="functionUnit">
                <div class="completion">
                  <div class="titles">
                    简历完整度:
                    <span>{{ percentage }}%</span>
                  </div>
                  <el-progress :percentage="percentage" :format="format" :text-inside="true" color="#ff552d">
                  </el-progress>
                  <div class="desc">
                    平台平均简历完整度为90%，快快完善简历提高竞争力
                  </div>
                </div>
                <div class="annex">
                  <!-- <div class="upload-title">附件简历</div> -->
                  <el-upload class="upload-demo" :action="uploadResume" :data="uploadResumeData" name="file"
                    :show-file-list="true" :file-list="fileList" :headers="myHeaders" :on-success="resumeSuccess"
                    :on-error="resumeError">
                    <div class="upload-btn">添加附件简历</div>
                  </el-upload>
                  <div class="upload-explain">
                    DOC、DOCX、PDF、JPG、PNG格式，文件大小不超过4M
                  </div>
                </div>
              </div>
              <el-tabs v-model="activeName" type="border-card" class="resumeTabs">
                <el-tab-pane label="基本信息" name="1">
                  <div class="updatedate">最后更新：{{ endUpdateDate }}</div>
                  <div class="basic clearfix">
                    <div class="infos">
                      <div class="clearfix">
                        <div class="title">
                          <h2>{{ PerfectList.username }}</h2>
                          <h4>【软件工程师 {{ PerfectList.workExp }}年】</h4>
                        </div>
                        <div class="btn">
                          <div class="btn-edit" @click="basicMeaasgeShow">
                            <i class="resumeicon"></i>编辑
                          </div>
                        </div>
                      </div>
                      <ul class="clearfix">
                        <li v-if="professioncertlistdata.length>0">
                          <i></i>
                          <span class="authentication" v-for="(item,index) in professioncertlistdata"
                            v-bind:key="item.id">
                            <span v-if="index!=professioncertlistdata.length-1">
                              {{item.certType}}、
                            </span>
                            <span v-else>
                              {{item.certType}}
                            </span>

                          </span>
                        </li>
                        <li>
                          <i></i>
                          {{ PerfectList.sex }}/ {{ PerfectList.age }}岁
                        </li>
                        <li>
                          <i></i>
                          {{ PerfectList.workExp }}年
                        </li>
                        <li>
                          <i></i>
                          {{ PerfectList.address }}
                        </li>
                        <li>
                          <i></i>
                          {{ PerfectList.education }}
                        </li>
                        <li>
                          <i></i>
                          {{ PerfectList.phone }}
                        </li>
                        <!-- <li><i></i> s15239527605@163.com</li> -->
                        <li>
                          <i></i>
                          {{ PerfectList.nativePlace }}
                        </li>
                      </ul>
                    </div>
                    <div class="head">
                      <el-upload :action="uploadUrl" list-type="picture-card" name="file" :headers="myHeaders"
                        :show-file-list="false" :on-success="handleAvatarSuccess">
                        <img v-if="avatarIdPath" :src="avatarIdPath" class="avatar" />
                        <img v-else src="../../assets/img/timgs.jpg" class="avatar" />
                        <div slot="tip" class="el-upload__tip">
                          只能上传jpg/png图片，且不超过2M
                        </div>
                      </el-upload>
                    </div>
                  </div>
                  <div class="basic_edit edit" v-if="basicMeaasge">
                    <div class="title">基本信息</div>
                    <el-form label-width="80px" :model="basicEditForm" label-position="left" :rules="basicEditRules"
                      ref="basicEditRef">
                      <el-form-item label="姓名:" prop="username">
                        <span>{{basicEditForm.username}}</span>
                      </el-form-item>
                      <el-form-item label="年龄:" prop="age">
                        <el-input placeholder class="controly" maxlength="2" v-model="basicEditForm.age"></el-input>
                      </el-form-item>
                      <el-form-item label="性别:" prop="sex">
                        <span>{{basicEditForm.sex}}</span>
                        <!-- <el-select
                          v-model="basicEditForm.sex"
                          placeholder="请选择"
                          style="width: 200px"
                          @change="getSex"
                        >
                          <el-option
                            v-for="item in sexList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.code"
                          ></el-option>
                        </el-select> -->
                      </el-form-item>
                      <el-form-item label="手机号码:">{{
                        PerfectList.phone
                      }}</el-form-item>
                      <el-form-item class="formL" label="最高学历" prop="education">
                        <el-select v-model="basicEditForm.education" placeholder="请选择" @change="getEducation"
                          class="controly">
                          <el-option v-for="item in educations" :key="item.value" :label="item.name" :value="item.code">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="工作年限:" prop="workExp">
                        <el-select v-model="basicEditForm.workExp" placeholder="请选择" class="controly"
                          @change="getworkExp">
                          <el-option v-for="(item, index) in workExpOptions" :key="index" :label="item.name"
                            :value="item.code"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item class="forma" label="现居住地" prop="address">
                        <el-input placeholder class="controly" v-model="basicEditForm.address.townName"></el-input>
                        <!-- <el-cascader
                          v-model="basicEditForm.address.county"
                          :options="cityList"
                          style="width: 200px"
                          @change="getAddress"
                        ></el-cascader> -->
                      </el-form-item>
                      <el-form-item class="forma" label="籍贯" prop="nativePlace">
                        <span>{{basicEditForm.nativePlace}}</span>
                        <!-- <el-cascader
                          v-model="basicEditForm.nativePlace.townCode"
                          :options="cityList"
                          style="width: 200px"
                          @change="getNativePlace"
                        ></el-cascader> -->
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="basicEdit">完成</el-button>
                        <el-button @click="basicFields">取消</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-tab-pane>
               
                    <el-tab-pane label="家庭社会关系" name="7">
                  <div class="workexp infos">
                    <div class="clearfix">
                      <div class="title">
                        <h2>家庭社会关系</h2>
                      </div>
                    </div>
                    <!-- 用于循环数据 -->
                    <div class="workexpList" v-for="(item, index) in relationExperienceList" :key="index">
                      <div class="btn">
                        <div class="btn-delete" @click="showfamily_Delete(item)">
                          <i class="resumeicon"></i>删除
                        </div>
                        <div class="btn-edit" @click="showfamily_edit(index)">
                          <i class="resumeicon"></i>编辑
                        </div>
                      </div>
                      <div class="workexp_edit edit" v-if="fid == index">
                        <el-form label-width="83px" :model="familySocialRelationsForm" label-position="left"
                          ref="familySocialRelationsRef" :rules="familySocialRelationsRules">
                          <el-form-item label="称谓：">
                            <el-select v-model="familySocialRelationsForm.relation" placeholder="请选择称谓">
                              <el-option v-for="item in appellationList" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="姓名: " prop="name">
                            <el-input placeholder="" class="control" v-model="familySocialRelationsForm.name">
                            </el-input>
                          </el-form-item>
                          <el-form-item label="年龄: " prop="age">
                            <el-input placeholder="" class="control" maxlength="2"
                              v-model="familySocialRelationsForm.age"></el-input>
                          </el-form-item>
                          <el-form-item label="政治面貌：">
                            <el-select v-model="
                                familySocialRelationsForm.policitalStatus
                              " placeholder="请选择政治面貌">
                              <el-option v-for="item in politicalOutlookList" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="工作单位: " prop="workUnit">
                            <el-input placeholder="" class="control" v-model="familySocialRelationsForm.workUnit">
                            </el-input>
                          </el-form-item>
                          <el-form-item label="工作职位: " prop="workPost">
                            <el-input placeholder="" class="control" v-model="familySocialRelationsForm.workPost">
                            </el-input>
                          </el-form-item>
                          <el-form-item label="联系电话:" prop="phone">
                            <el-input placeholder="" class="control" v-model="familySocialRelationsForm.phone">
                            </el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-button type="primary" @click="familySocialRelationsAdd1(item, index)">完成</el-button>
                            <el-button @click="familyExperience1(index)">取消</el-button>
                          </el-form-item>
                        </el-form>
                      </div>
                      <div class="Project Title" v-else>
                        <dl class="content clearfix">
                          <dt class="com">{{ item.relation }}</dt>
                          <!-- <dd class="com">
                            {{ item.starDate }}~{{ item.endDate }}
                          </dd> -->
                        </dl>
                        <dl class="content clearfix">
                          <dt>姓名：</dt>
                          <dd>{{ item.name }}</dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>年龄：</dt>
                          <dd>{{ item.age }}</dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>政治面貌：</dt>
                          <dd>{{ item.policitalStatus }}</dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>工作单位：</dt>
                          <dd>{{ item.workUnit }}</dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>工作职位：</dt>
                          <dd>{{ item.workPost }}</dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>联系电话：</dt>
                          <dd>{{ item.phone }}</dd>
                        </dl>
                      </div>
                    </div>
                    <!-- 添加家庭社会关系 -->
                    <div class="commonaddbtn" @click="showfamily_add">
                      <i class="resumeicon"></i>添加家庭社会关系
                    </div>
                    <div class="workexp_edit edit" v-if="familySocialRelationsShow">
                      <el-form label-width="83px" :model="familySocialRelationsForm" label-position="left"
                        ref="familySocialRelationsRef" :rules="familySocialRelationsRules">
                        <el-form-item label="称谓：">
                          <el-select v-model="familySocialRelationsForm.relation" placeholder="请选择称谓">
                            <el-option v-for="item in appellationList" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="姓名: " prop="name">
                          <el-input placeholder="" class="control" v-model="familySocialRelationsForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="年龄: " prop="age">
                          <el-input placeholder="" class="control" v-model="familySocialRelationsForm.age"></el-input>
                        </el-form-item>
                        <el-form-item label="政治面貌：">
                          <el-select v-model="familySocialRelationsForm.policitalStatus" placeholder="请选择政治面貌">
                            <el-option v-for="item in politicalOutlookList" :key="item.value" :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="工作单位: " prop="workUnit">
                          <el-input placeholder="" class="control" v-model="familySocialRelationsForm.workUnit">
                          </el-input>
                        </el-form-item>
                        <el-form-item label="工作职位: " prop="workPost">
                          <el-input placeholder="" class="control" v-model="familySocialRelationsForm.workPost">
                          </el-input>
                        </el-form-item>
                        <el-form-item label="联系电话:" prop="phone">
                          <el-input placeholder="" class="control" v-model="familySocialRelationsForm.phone"></el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" @click="familySocialRelationsAdd">完成</el-button>
                          <el-button @click="familyExperience">取消</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="教育经历" name="4">
                  <div class="workexp infos" ref="schoolexp">
                    <div class="clearfix">
                      <div class="title">
                        <h2>教育经历</h2>
                      </div>
                    </div>
                    <!-- 用于循环数据 -->
                    <div class="schoolList" v-for="(item, index) in schoolExperiences" :key="index">
                      <div class="btn">
                        <div class="btn-delete" @click="showSchool_Delete(item)">
                          <i class="resumeicon"></i>删除
                        </div>
                        <div class="btn-edit" @click="showSchool_edit(index)">
                          <i class="resumeicon"></i>编辑
                        </div>
                      </div>
                      <div class="workexp_edit edit" v-if="sid == index">
                        <el-form label-width="83px" :model="schoolExperienceForm" label-position="left"
                          ref="schoolExperienceRef" :rules="schoolExperienceRules">
                          <el-form-item label="学校名称:" prop="schoolName">
                            <el-input placeholder="" class="control" v-model="schoolExperienceForm.schoolName">
                            </el-input>
                          </el-form-item>
                          <el-form-item label="专业名称:" prop="majorName">
                            <el-input placeholder="" class="control" v-model="schoolExperienceForm.majorName">
                            </el-input>
                          </el-form-item>
                          <el-form-item label="毕业时间:" prop="graduateStartDate">
                            <el-date-picker type="daterange" v-model="schoolExperienceForm.graduateStartDate"
                              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                              value-format="yyyy-MM-dd" class="control"></el-date-picker>
                          </el-form-item>
                          <el-form-item label="在校经历:">
                            <el-input type="textarea" v-model="schoolExperienceForm.schoolExperience" class="controlx"
                              :rows="5"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-button type="primary" @click="schoolExperienceEdit(item, index)">完成</el-button>
                            <el-button @click="schoolExperienceRes1(index)">取消</el-button>
                          </el-form-item>
                        </el-form>
                      </div>
                      <div class="school Title" v-else>
                        <dl class="content clearfix">
                          <dt class="com">{{ item.schoolName }}</dt>
                          <dd class="com">
                            {{ item.starDate }}~{{ item.endDate }}
                          </dd>
                        </dl>
                        <dl class="content clearfix">
                          <dd>{{ item.majorName }}</dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>在校经历：</dt>
                          <dd>{{ item.schoolExperience }}</dd>
                        </dl>
                      </div>
                    </div>
                    <!-- 添加教育经历 -->
                    <div class="commonaddbtn" @click="showSchool_add">
                      <i class="resumeicon"></i>添加教育经历
                    </div>
                    <div class="workexp_edit edit" v-if="shoolShowAdd">
                      <el-form label-width="83px" :model="schoolExperienceForm" label-position="left"
                        ref="schoolExperienceRef" :rules="schoolExperienceRules">
                        <el-form-item label="学校名称:" prop="schoolName">
                          <el-input placeholder="" class="control" v-model="schoolExperienceForm.schoolName"></el-input>
                        </el-form-item>
                        <el-form-item label="专业名称:" prop="majorName">
                          <el-input placeholder="" class="control" v-model="schoolExperienceForm.majorName"></el-input>
                        </el-form-item>
                        <el-form-item label="毕业时间:" prop="graduateStartDate">
                          <el-date-picker type="daterange" v-model="schoolExperienceForm.graduateStartDate"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            value-format="yyyy-MM-dd" class="control"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="在校经历:">
                          <el-input type="textarea" v-model="schoolExperienceForm.schoolExperience" class="controlx"
                            :rows="5"></el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" @click="schoolExperienceAdd">完成</el-button>
                          <el-button @click="schoolExperienceRes2">取消</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </el-tab-pane>
                 <el-tab-pane label="求职意向" name="2">
                  <div class="jobobjective infos">
                    <div class="clearfix">
                      <div class="title">
                        <h2>求职意向</h2>
                      </div>
                      <div class="btn">
                        <div class="btn-edit" @click="showJobobjective">
                          <i class="resumeicon"></i>编辑
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <span>{{ PerfectList.expectJobName }}</span>
                      <i>|</i>
                      <span>{{ PerfectList.expectSalary }}/月</span>
                      <i>|</i>
                      <span>{{ PerfectList.workAddress }}</span>
                    </div>
                  </div>
                  <div class="jobobjective_edit edit" v-if="jobobjectiveShow">
                    <div class="title">求职意向</div>
                    <el-form label-width="83px" :model="jobobjective_editForm" label-position="left"
                      ref="jobobjective_editRef" :rules="jobobjective_editRules">
                      <el-form-item class="formL" label="期望职位:" prop="expectJobs">
                        <el-cascader v-model="jobobjective_editForm.expectJobs" :options="expectJobsOpt"
                          @change="getCascader" class="control"></el-cascader>
                      </el-form-item>
                      <el-form-item label="期望薪资:" prop="expectSalary">
                        <el-select v-model="jobobjective_editForm.expectSalaryCode" placeholder="请选择" class="control"
                          @change="getexpectSalary">
                          <el-option v-for="(item, index) in expectSalaryList" :key="index" :label="item.name"
                            :value="item.code"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item class="forma" label="工作地点" prop="workAddress">
                        <el-cascader v-model="jobobjective_editForm.workAddress.townCode" :options="cityList"
                          style="width: 200px" @change="getWorkAddress"></el-cascader>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="jobobjectiveEdit">完成</el-button>
                        <el-button @click="jobResetFields">取消</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="工作经历" name="3">
                  <div class="workexp infos" ref="workexp">
                    <div class="clearfix">
                      <div class="title">
                        <h2>工作经历</h2>
                      </div>
                    </div>
                    <!-- 用于循环数据 -->
                    <div class="workexpList" v-for="(item, index) in resumeperfectObj" :key="index">
                      <div class="btn">
                        <div class="btn-delete" @click="showworkexp_Delete(item)">
                          <i class="resumeicon"></i>删除
                        </div>
                        <div class="btn-edit" @click="showworkexp_edit(index)">
                          <i class="resumeicon"></i>编辑
                        </div>
                      </div>
                      <div class="workexp_edit edit" v-if="jid == index">
                        <el-form label-width="83px" :model="workExperienceForm" label-position="left"
                          ref="workExperienceRef" :rules="workExperienceRules">
                          <el-form-item label="公司名称:" prop="companyName">
                            <el-input placeholder="" class="control" v-model="workExperienceForm.companyName">
                            </el-input>
                          </el-form-item>
                          <el-form-item label="职位名称:" prop="positionName">
                            <el-input placeholder="" class="control" v-model="workExperienceForm.positionName">
                            </el-input>
                          </el-form-item>
                          <el-form-item label="薪资:" prop="salary">
                            <el-input placeholder="" class="control" v-model="workExperienceForm.salary"></el-input>
                          </el-form-item>
                          <el-form-item label="在职时间:" prop="workEndDate">
                            <el-date-picker type="daterange" v-model="workExperienceForm.workEndDate"
                              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                              value-format="yyyy-MM-dd" class="control"></el-date-picker>
                          </el-form-item>
                          <el-form-item label="工作职责:">
                            <el-input type="textarea" v-model="workExperienceForm.jobContent" class="controlx"
                              :rows="5"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-button type="primary" @click="workExperienceEdit(item, index)">完成</el-button>
                            <el-button @click="workExperienceRes1(index)">取消</el-button>
                          </el-form-item>
                        </el-form>
                      </div>
                      <div class="worke Title" v-else>
                        <dl class="content clearfix">
                          <dt class="com">{{ item.companyName }}</dt>
                          <dd class="com">
                            {{ item.starDate }}~{{ item.endDate }}
                          </dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>职位名称：</dt>
                          <dd>{{ item.positionName }}</dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>薪资：</dt>
                          <dd>{{ item.salary }}元</dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>工作职责：</dt>
                          <dd class="words">{{ item.jobContent }}</dd>
                        </dl>
                      </div>
                    </div>
                    <!-- 添加工作经历 -->
                    <div class="commonaddbtn" @click="showworkexp_add">
                      <i class="resumeicon"></i>添加工作经历
                    </div>
                    <div class="workexp_edit edit" v-if="workexpShowAdd">
                      <!-- <div class="title">工作经历</div> -->
                      <el-form label-width="83px" :model="workExperienceForm" label-position="left"
                        ref="workExperienceRef" :rules="workExperienceRules">
                        <el-form-item label="公司名称:" prop="companyName">
                          <el-input placeholder="" class="control" v-model="workExperienceForm.companyName"></el-input>
                        </el-form-item>
                        <el-form-item label="职位名称:" prop="positionName">
                          <el-input placeholder="" class="control" v-model="workExperienceForm.positionName"></el-input>
                        </el-form-item>
                        <el-form-item label="薪资:" prop="salary">
                          <el-input placeholder="" class="control" v-model="workExperienceForm.salary"></el-input>
                        </el-form-item>
                        <el-form-item label="在职时间:" prop="workEndDate">
                          <el-date-picker type="daterange" v-model="workExperienceForm.workEndDate" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" class="control">
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="工作内容:">
                          <el-input type="textarea" v-model="workExperienceForm.jobContent" class="controlx" :rows="5">
                          </el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" @click="workExperienceAdd">完成</el-button>
                          <el-button @click="workExperienceRes2">取消</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="项目经验" name="5">
                  <div class="workexp infos">
                    <div class="clearfix">
                      <div class="title">
                        <h2>项目经验</h2>
                      </div>
                    </div>
                    <!-- 用于循环数据 -->
                    <div class="workexpList" v-for="(item, index) in ProjectExperience" :key="index">
                      <div class="btn">
                        <div class="btn-delete" @click="showProject_Delete(item)">
                          <i class="resumeicon"></i>删除
                        </div>
                        <div class="btn-edit" @click="showProject_edit(index)">
                          <i class="resumeicon"></i>编辑
                        </div>
                      </div>
                      <div class="workexp_edit edit" v-if="pid == index">
                        <el-form label-width="83px" :model="ProjectExperienceForm" label-position="left"
                          ref="ProjectExperienceRef" :rules="ProjectExperienceRules">
                          <el-form-item label="项目名称:" prop="projectName">
                            <el-input placeholder="" class="control" v-model="ProjectExperienceForm.projectName">
                            </el-input>
                          </el-form-item>
                          <el-form-item label="职位名称:" prop="positionName">
                            <el-input placeholder="" class="control" v-model="ProjectExperienceForm.positionName">
                            </el-input>
                          </el-form-item>
                          <el-form-item label="项目时间:" prop="projectStartDate">
                            <el-date-picker type="daterange" v-model="ProjectExperienceForm.projectStartDate"
                              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                              value-format="yyyy-MM-dd" class="control"></el-date-picker>
                          </el-form-item>
                          <el-form-item label="项目介绍:">
                            <el-input type="textarea" v-model="ProjectExperienceForm.projectIntroduce" class="controlx"
                              :rows="5"></el-input>
                          </el-form-item>
                          <el-form-item label="项目业绩:">
                            <el-input type="textarea" v-model="ProjectExperienceForm.projectAchievement"
                              class="controlx" :rows="5"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-button type="primary" @click="ProjectExperienceEdit(item, index)">完成</el-button>
                            <el-button @click="ProjectExperience1(index)">取消</el-button>
                          </el-form-item>
                        </el-form>
                      </div>
                      <div class="Project Title" v-else>
                        <dl class="content clearfix">
                          <dt class="com">{{ item.projectName }}</dt>
                          <dd class="com">
                            {{ item.starDate }}~{{ item.endDate }}
                          </dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>职位名称：</dt>
                          <dd>{{ item.positionName }}</dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>项目介绍：</dt>
                          <dd>{{ item.projectIntroduce }}</dd>
                        </dl>
                        <dl class="content clearfix">
                          <dt>项目业绩：</dt>
                          <dd>{{ item.projectAchievement }}</dd>
                        </dl>
                      </div>
                    </div>
                    <!-- 添加项目经验 -->
                    <div class="commonaddbtn" @click="showProject_add">
                      <i class="resumeicon"></i>添加项目经验
                    </div>
                    <div class="workexp_edit edit" v-if="ProjectShowAdd">
                      <el-form label-width="83px" :model="ProjectExperienceForm" label-position="left"
                        ref="ProjectExperienceRef" :rules="ProjectExperienceRules">
                        <el-form-item label="项目名称:" prop="projectName">
                          <el-input placeholder="" class="control" v-model="ProjectExperienceForm.projectName">
                          </el-input>
                        </el-form-item>
                        <el-form-item label="职位名称:" prop="positionName">
                          <el-input placeholder="" class="control" v-model="ProjectExperienceForm.positionName">
                          </el-input>
                        </el-form-item>
                        <el-form-item label="项目时间:" prop="projectStartDate">
                          <el-date-picker type="daterange" v-model="ProjectExperienceForm.projectStartDate"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            value-format="yyyy-MM-dd" class="control"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="项目介绍:">
                          <el-input type="textarea" v-model="ProjectExperienceForm.projectIntroduce" class="controlx"
                            :rows="5"></el-input>
                        </el-form-item>
                        <el-form-item label="项目业绩:">
                          <el-input type="textarea" v-model="ProjectExperienceForm.projectAchievement" class="controlx"
                            :rows="5"></el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" @click="ProjectExperienceAdd">完成</el-button>
                          <el-button @click="ProjectExperience2">取消</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="自我评价" name="6">
                  <div class="workexp infos" ref="Selfexp">
                    <div class="clearfix">
                      <div class="title">
                        <h2>自我评价</h2>
                      </div>
                    </div>
                    <!-- 用于循环数据 -->
                    <div class="workexpList">
                      <div class="btn">
                        <!-- <div class="btn-delete"><i class="resumeicon"></i>删除</div> -->
                        <div class="btn-edit" @click="SelfEvaluation_edit">
                          <i class="resumeicon"></i>编辑
                        </div>
                      </div>
                      <dl class="content clearfixs">
                        <dd class="schools">
                          {{ PerfectList.selfAssessment }}
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div class="workexp_edit edit" v-if="SelfEvaluationShow">
                    <div class="title">自我评价</div>
                    <el-form label-width="80px" :model="SelfEvaluationForm" label-position="left" ref="form">
                      <el-form-item label="自我评价:" prop="selfAssessment">
                        <el-input type="textarea" v-model="SelfEvaluationForm.selfAssessment" class="controls"
                          :rows="5"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="selfAssessmentEdit">完成</el-button>
                        <el-button @click="selfAssessmentRes">取消</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-tab-pane>
            
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import userleft from '@/components/userleft.vue';
import { candidate } from '@/api/resumeview';
import { professioncertlist } from '@/api/user';

import {
  job,
  jobDelete,
  education,
  educationDelete,
  projectAdd,
  projectDelete,
  familyMember,
  familyMemberDelete,
} from '@/api/resumeperfect';
// import { businessDelete } from '@/api/store';
import { ExpectJobs, postResumes, ExpectJobs1 } from '@/api/resume';
import { cityjson } from '@/utils/cityjson.js';
export default {
  name: 'newResumeperfect',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    return {
      professioncertlistdata: [],
      activeName: '1',
      //  工作经历
      workexpShow: false,
      // 教育经历
      schoolShow: false,
      // 自我分享
      SelfEvaluationShow: false,
      // 项目经验
      ProjectShow: false,
      // 基本信息
      basicMeaasge: false,
      // 基本信息表单
      form: {
        region: '',
      },
      // 获取简历修改页面的数据
      PerfectList: {},
      // 获取头像的路径
      avatarIdPath: '',
      // 获取头像的id
      avatarId: '',
      // 求职意向隐藏与显示
      jobobjectiveShow: false,
      // 求职意向
      jobobjective_editForm: {
        expectJobs: '',
        expectSalary: '',
        expectSalaryCode: '',
        workAddress: {
          // 省
          provinces: '',
          provinceName: '',
          provinceCode: '',
          // 市
          citys: '',
          citysName: '',
          cityCode: '',
          // 镇
          towns: '',
          townName: '',
          townCode: '',
        },
        // 期望城市编码
        cityCode: '',
      },
      // 求职意愿规则
      jobobjective_editRules: {
        expectJobs: [{ required: true, message: '不能为空', trigger: 'blur' }],
        expectSalary: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
      },
      // 期望职业数据
      expectJobsOpt: [],
      // 期望薪资的数据
      expectSalaryList: [],
      // uid: 230,
      // 修改简历的参数
      bean: {
        expectJobs: 0,
        workArea: {
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          longitude: '',
          latitude: '',
        },
        expectSalary: '',
        expectSalaryCode: '',
        avatarId: '',
        username: '',
        sex: '',
        sexCode: '',
        age: 0,
        workExp: 0,
        education: '',
        educationCode: '',
        phone: '',
        phoneCode: '',
        nativeArea: {
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          longitude: '',
          latitude: '',
        },
        residenceArea: {
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          longitude: '',
          latitude: '',
        },
        selfAssessment: '',
        resume: {
          fileToken: '',
          url: '',
          name: '',
          length: 0,
          sign: '',
        },
        privacySetting: 0,
        id: 0,
      },
      // 主键id
      id: 0,
      // 基本信息
      basicEditForm: {
        username: '',
        age: '',
        sex: '',
        sexCode: '',
        education: '',
        educationCode: '',
        workExp: 0,
        address: {
          // 省
          provinces: '',
          provinceName: '',
          provinceCode: '',
          // 市
          citys: '',
          citysName: '',
          cityCode: '',
          // 镇
          towns: '',
          townName: '',
          townCode: '',
        },
        nativePlace: {
          // 省
          provinces: '',
          provinceName: '',
          provinceCode: '',
          // 市
          citys: '',
          citysName: '',
          cityCode: '',
          // 镇
          towns: '',
          townName: '',
          townCode: '',
        },
      },
      // 基本信息规则
      basicEditRules: {
        username: [{ required: true, message: '不能为空', trigger: 'blur' }],
        age: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      // 学历
      educations: [],
      // 工作年限
      workExpOptions: [
        {
          code: 1,
          name: '一年以上',
        },
        {
          code: 2,
          name: '两年以上',
        },
        {
          code: 3,
          name: '三年以上',
        },
        {
          code: 4,
          name: '四年以上',
        },
        {
          code: 5,
          name: '五年以上',
        },
        {
          code: 6,
          name: '六年以上',
        },
        {
          code: 7,
          name: '七年以上',
        },
        {
          code: 8,
          name: '八年以上',
        },
        {
          code: 9,
          name: '九年以上',
        },
        {
          code: 10,
          name: '十年以上',
        },
      ],
      // 自我介绍
      SelfEvaluationForm: {
        selfAssessment: '',
      },
      // 性别数据
      sexList: [],
      // 获取工作经历数组数据
      resumeperfectObj: [],
      // 获取教育经历数组数据
      schoolExperiences: [],
      // 获取项目经验数组数据
      ProjectExperience: [],
      // 工作经历
      workExperienceForm: {
        // 职位
        positionName: '',
        // 薪资
        salary: 0,
        // 公司名称
        companyName: '',
        // 在职时间
        workEndDate: [],
        // 作业内容
        jobContent: '',
      },
      // 添加工作经历显示与隐藏
      workexpShowAdd: false,
      // 添加教育经历显示与隐藏
      shoolShowAdd: false,
      // 添加项目经验显示与隐藏
      ProjectShowAdd: false,
      // 添加家庭社会成员关系显示与隐藏
      familySocialRelationsShow: false,
      // 工作经历规则
      workExperienceRules: {
        JobTitle: [{ required: true, message: '不能为空', trigger: 'blur' }],
        corporateName: [
          { required: true, message: '不能为空', trigger: 'blur' },
        ],
      },
      // 教育经历规则
      schoolExperienceRules: {
        schoolName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        majorName: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      jid: -1,
      sid: -1,
      pid: -1,
      fid: -1,
      // 教育经验
      schoolExperienceForm: {
        // 学校名称
        schoolName: '',
        // 专业名称
        majorName: '',
        // 毕业时间:
        graduateStartDate: [],
        // 在校经历
        schoolExperience: '',
      },
      // 项目经验
      ProjectExperienceForm: {
        // 项目名称
        projectName: '',
        // 职位名称
        positionName: '',
        // 项目时间
        projectStartDate: [],
        // 项目介绍
        projectIntroduce: '',
        // 项目业绩
        projectAchievement: '',
      },
      // 项目经验规则
      ProjectExperienceRules: {
        entryName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        JobTitle: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      // 上传简历token
      myHeaders: { Authorization: 'Bearer ' + token },
      //UploadeUrl地址
      uploadUrl: this.$uplodeUrl + '/utils/uploadfile',
      // 上传简历的地址
      uploadResume: this.$uplodeUrl + '/utils/uploadfile',
      // 上传的参数
      uploadResumeData: {
        fileType: 'candidate',
        fileTypeCode: '简历',
      },
      // 修改简历的参数
      attachmentFiles: [{ key: 0, value: '' }],
      // 上传简历列表
      fileList: [{ name: '', url: '' }],
      // 进度条数据
      percentage: 0,
      // 最后更新时间
      endUpdateDate: '',
      // 简历id
      candidateId: 0,
      // 城市列表数据
      cityList: cityjson,
      // 修改工作经历的主建id
      workId: 0,
      // 家庭称谓
      appellationValue: '',
      // 家庭称谓名称列表
      appellationList: [
        { value: '父亲', label: '父亲' },
        { value: '母亲', label: '母亲' },
        { value: '儿子', label: '儿子' },
        { value: '女儿', label: '女儿' },
        { value: '丈夫', label: '丈夫' },
        { value: '妻子', label: '妻子' },
      ],
      // 政治面貌
      politicalOutlook: '',
      // 政治面貌列表
      politicalOutlookList: [
        { value: '党员', label: '党员' },
        { value: '群众', label: '群众' },
      ],
      // 家庭社会成员关系
      familySocialRelationsForm: {
        relation: '',
        name: '',
        age: '',
        policitalStatus: '',
        workUnit: '',
        workPost: '',
        phone: '',
      },
      // 家庭社会成员关系表单规则
      familySocialRelationsRules: {
        name: [{ required: true, message: '不能为空', trigger: 'blur' }],
        age: [{ required: true, message: '不能为空', trigger: 'blur' }],
        phone: [
          { required: true, message: '不能为空', trigger: 'blur' },
          { pattern: /^\d{11}$/, message: '格式错误，仅支持11位数字' },
        ],
      },
      // 获家庭社会成员关系数组数据
      relationExperienceList: [],
    };
  },
  mounted() {
    this.candidateId = this.$route.query.id;
    let candidateId = this.candidateId;
    this.getPerfectList(candidateId);
    this.getExpectJobs();
    this.getCandidatefilter();
    this.onprofessioncertlist();
  },
  methods: {
    format(percentage) {
      return '';
    },
    // 选择居住地事件
    async getAddress(e) {
      this.getLoop(e[0], this.cityList);
      this.basicEditForm.address.provinceCode = e[0];
      this.basicEditForm.address.provinceName = this.place;
      // 市
      let code = e[0];
      const res = await ExpectJobs1(code);
      let countys = res.data.result.countys;
      this.getLoop1(e[1], countys);
      this.basicEditForm.address.cityCode = e[1];
      this.basicEditForm.address.citysName = this.place1;
      // 县
      let code1 = e[1];
      const res1 = await ExpectJobs1(code1);
      let countys1 = res1.data.result.countys;
      this.getLoop2(e[2], countys1);
      this.basicEditForm.address.townCode = e[2];
      this.basicEditForm.address.townName = this.place2;
    },
    onprofessioncertlist() {
      var that = this;
      var data = { verifyStatus: 1 };
      professioncertlist(data).then((res) => {
        if (res.data.code === 1) {
          that.professioncertlistdata = res.data.result;
        }
      });
    },
    // 选择籍贯事件
    async getNativePlace(e) {
      this.getLoop(e[0], this.cityList);
      this.basicEditForm.nativePlace.provinceCode = e[0];
      this.basicEditForm.nativePlace.provinceName = this.place;
      // 市
      let code = e[0];
      const res = await ExpectJobs1(code);
      let countys = res.data.result.countys;
      this.getLoop1(e[1], countys);
      this.basicEditForm.nativePlace.cityCode = e[1];
      this.basicEditForm.nativePlace.citysName = this.place1;
      // 县
      let code1 = e[1];
      const res1 = await ExpectJobs1(code1);
      let countys1 = res1.data.result.countys;
      this.getLoop2(e[2], countys1);
      this.basicEditForm.nativePlace.townCode = e[2];
      this.basicEditForm.nativePlace.townName = this.place2;
    },
    // 选择工作地点事件
    async getWorkAddress(e) {
      this.getLoop(e[0], this.cityList);
      this.jobobjective_editForm.workAddress.provinceCode = e[0];
      this.jobobjective_editForm.workAddress.provinceName = this.place;
      // 市
      let code = e[0];
      const res = await ExpectJobs1(code);
      let countys = res.data.result.countys;
      this.getLoop1(e[1], countys);
      this.jobobjective_editForm.workAddress.cityCode = e[1];
      this.jobobjective_editForm.workAddress.citysName = this.place1;
      // 县
      let code1 = e[1];
      const res1 = await ExpectJobs1(code1);
      let countys1 = res1.data.result.countys;
      this.getLoop2(e[2], countys1);
      this.jobobjective_editForm.workAddress.townCode = e[2];
      this.jobobjective_editForm.workAddress.townName = this.place2;
    },
    // 修改工作经历
    showworkexp_edit(index) {
      this.jid = index;
      this.workExperienceForm.companyName = this.resumeperfectObj[
        index
      ].companyName;
      this.workExperienceForm.jobContent = this.resumeperfectObj[
        index
      ].jobContent;
      this.workExperienceForm.positionName = this.resumeperfectObj[
        index
      ].positionName;
      this.workExperienceForm.salary = this.resumeperfectObj[index].salary;
      let starDate = this.$formatUTC(
        this.resumeperfectObj[index].workStartDate
      );
      let endDate = this.$formatUTC(this.resumeperfectObj[index].workEndDate);
      let arr = this.workExperienceForm.workEndDate;
      if (arr.length == 0) {
        arr.push(starDate, endDate);
      } else if (arr.length > 0 && arr.length < 3) {
        arr.splice(0, arr.length);
        arr.push(starDate, endDate);
      } else {
        return;
      }
    },
    // 添加工作经历
    showworkexp_add() {
      this.workExperienceForm.positionName = '';
      this.workExperienceForm.salary = 0;
      this.workExperienceForm.companyName = '';
      this.workExperienceForm.workEndDate = [];
      this.workExperienceForm.jobContent = '';
      this.workexpShowAdd = true;
    },
    // 工作经历删除
    async showworkexp_Delete(item) {
      let id = item.id;
      let jobLength = this.PerfectList.jobExperienceList.length;
      if (jobLength == 1) {
        this.percentage = this.percentage - 10;
      } else {
      }
      const { data: res } = await jobDelete(id);
      if (res.code == 1) {
        this.$message.success('删除成功!');
        this.getPercentage();
      } else {
        return this.$message.error('删除失败！');
      }
    },
    // 修改教育经验
    showSchool_edit(index) {
      this.sid = index;
      this.schoolExperienceForm.schoolName = this.schoolExperiences[
        index
      ].schoolName;
      this.schoolExperienceForm.majorName = this.schoolExperiences[
        index
      ].majorName;
      // this.schoolExperienceForm.GraduationTime = this.schoolExperiences[
      //   index
      // ].GraduationTime;
      this.schoolExperienceForm.schoolExperience = this.schoolExperiences[
        index
      ].schoolExperience;
      let starDate = this.$formatUTC(
        this.schoolExperiences[index].graduateStartDate
      );
      let endDate = this.$formatUTC(
        this.schoolExperiences[index].graduateEndDate
      );
      let arr = this.schoolExperienceForm.graduateStartDate;
      if (arr.length == 0) {
        arr.push(starDate, endDate);
      } else if (arr.length > 0 && arr.length < 3) {
        arr.splice(0, arr.length);
        arr.push(starDate, endDate);
      } else {
        return;
      }
    },
    // 添加教育经历
    showSchool_add() {
      this.schoolExperienceForm.schoolName = '';
      this.schoolExperienceForm.majorName = '';
      this.schoolExperienceForm.graduateStartDate = [];
      this.schoolExperienceForm.schoolExperience = '';
      this.shoolShowAdd = true;
    },
    // 教育经历删除
    async showSchool_Delete(item) {
      let id = item.id;
      let educationLength = this.PerfectList.educationExperienceList.length;
      if (educationLength == 1) {
        this.percentage = this.percentage - 10;
      } else {
      }
      const { data: res } = await educationDelete(id);
      if (res.code == 1) {
        this.$message.success('删除成功!');
        this.getPercentage();
      } else {
        return this.$message.error('删除失败！');
      }
    },
    // 自我分享
    SelfEvaluation_edit() {
      this.SelfEvaluationForm.selfAssessment = this.PerfectList.selfAssessment;
      this.SelfEvaluationShow = true;
    },
    // 修改项目经验
    showProject_edit(index) {
      this.pid = index;
      this.ProjectExperienceForm.projectName = this.ProjectExperience[
        index
      ].projectName;
      this.ProjectExperienceForm.positionName = this.ProjectExperience[
        index
      ].positionName;
      this.ProjectExperienceForm.projectIntroduce = this.ProjectExperience[
        index
      ].projectIntroduce;
      this.ProjectExperienceForm.projectAchievement = this.ProjectExperience[
        index
      ].projectAchievement;
      let starDate = this.$formatUTC(
        this.ProjectExperience[index].projectStartDate
      );
      let endDate = this.$formatUTC(
        this.ProjectExperience[index].projectEndDate
      );
      let arr = this.ProjectExperienceForm.projectStartDate;
      if (arr.length == 0) {
        arr.push(starDate, endDate);
      } else if (arr.length > 0 && arr.length < 3) {
        arr.splice(0, arr.length);
        arr.push(starDate, endDate);
      } else {
        return;
      }
    },
    // 添加项目经验
    showProject_add() {
      this.ProjectExperienceForm.projectName = '';
      this.ProjectExperienceForm.positionName = '';
      this.ProjectExperienceForm.projectStartDate = [];
      this.ProjectExperienceForm.projectIntroduce = '';
      this.ProjectExperienceForm.projectAchievement = '';
      this.ProjectShowAdd = true;
    },
    // 项目经验删除
    async showProject_Delete(item) {
      let id = item.id;
      let projectLength = this.PerfectList.projectExperienceList.length;
      if (projectLength == 1) {
        this.percentage = this.percentage - 10;
      } else {
      }
      const { data: res } = await projectDelete(id);
      if (res.code == 1) {
        this.$message.success('删除成功!');
        this.getPercentage();
      } else {
        return this.$message.error('删除失败！');
      }
    },
    // 修改家庭社会成员关系
    showfamily_edit(index) {
      this.fid = index;
      this.familySocialRelationsForm.relation = this.relationExperienceList[
        index
      ].relation;
      this.familySocialRelationsForm.name = this.relationExperienceList[
        index
      ].name;
      this.familySocialRelationsForm.age = this.relationExperienceList[
        index
      ].age;
      this.familySocialRelationsForm.policitalStatus = this.relationExperienceList[
        index
      ].policitalStatus;
      this.familySocialRelationsForm.workUnit = this.relationExperienceList[
        index
      ].workUnit;
      this.familySocialRelationsForm.workPost = this.relationExperienceList[
        index
      ].workPost;
      this.familySocialRelationsForm.phone = this.relationExperienceList[
        index
      ].phone;
    },
    // 添加家庭社会成员关系
    showfamily_add() {
      this.familySocialRelationsForm.relation = '';
      this.familySocialRelationsForm.name = '';
      this.familySocialRelationsForm.age = '';
      this.familySocialRelationsForm.policitalStatus = '';
      this.familySocialRelationsForm.workUnit = '';
      this.familySocialRelationsForm.workPost = '';
      this.familySocialRelationsForm.phone = '';
      this.familySocialRelationsShow = true;
    },
    // 家庭社会成员关系删除
    async showfamily_Delete(item) {
      let id = item.id;
      const { data: res } = await familyMemberDelete(id);
      if (res.code == 1) {
        this.$message.success('删除成功!');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
      } else {
        return this.$message.error('删除失败！');
      }
    },
    // 基本信息
    basicMeaasgeShow() {
      // 基本信息回显
      this.basicMeaasge = true;
      this.basicEditForm.username = this.PerfectList.username;
      this.basicEditForm.age = this.PerfectList.age;
      this.basicEditForm.sex = this.PerfectList.sex;
      this.basicEditForm.sexCode = this.PerfectList.sexCode;
      this.basicEditForm.education = this.PerfectList.education;
      this.basicEditForm.educationCode = this.PerfectList.educationcode;
      this.basicEditForm.workExp = this.PerfectList.workExp;

      this.basicEditForm.address.provinceName = this.PerfectList.dWellGisCoord.province;
      this.basicEditForm.address.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.basicEditForm.address.citysName = this.PerfectList.dWellGisCoord.city;
      this.basicEditForm.address.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.basicEditForm.address.townName = this.PerfectList.dWellGisCoord.county;
      this.basicEditForm.address.townCode = this.PerfectList.dWellGisCoord.countyCode;

      this.basicEditForm.nativePlace.provinceName = this.PerfectList.nativeGisCoord.province;
      this.basicEditForm.nativePlace.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.basicEditForm.nativePlace.citysName = this.PerfectList.nativeGisCoord.city;
      this.basicEditForm.nativePlace.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.basicEditForm.nativePlace.townName = this.PerfectList.nativeGisCoord.county;
      this.basicEditForm.nativePlace.townCode = this.PerfectList.nativeGisCoord.countyCode;
      this.basicEditForm.nativePlace = this.PerfectList.nativePlace;
    },
    // 求职意向
    showJobobjective() {
      this.jobobjectiveShow = true;
      // 求职意向回显
      this.jobobjective_editForm.expectJobs = this.PerfectList.expectJobs;
      this.jobobjective_editForm.expectSalary = this.PerfectList.expectSalary;
      this.jobobjective_editForm.expectSalaryCode = this.PerfectList.expectSalaryCode;

      this.jobobjective_editForm.workAddress.provinceName = this.PerfectList.workGisCoord.province;
      this.jobobjective_editForm.workAddress.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.jobobjective_editForm.workAddress.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.jobobjective_editForm.workAddress.citysName = this.PerfectList.workGisCoord.city;
      this.jobobjective_editForm.workAddress.townCode = this.PerfectList.workGisCoord.countyCode;
      this.jobobjective_editForm.workAddress.townName = this.PerfectList.workGisCoord.county;
    },
    // 获取求职列表的详情信息
    async getPerfectList(id) {
      const { data: res } = await candidate(id);
      console.log(res);
      if (res.code == 1) {
        this.PerfectList = res.result;
        this.id = res.result.id;
        // 头像的路径
        this.avatarIdPath = res.result.avatarPath;
        // 简历列表的数据
        this.fileList = res.result.resumeList;
        if (this.fileList !== null) {
          for (let i = 0; i < this.fileList.length; i++) {
            this.fileList[i].name = res.result.resumeList[i].fileName;
          }
        } else {
          this.fileList = [];
        }
        // 进度条数据
        this.percentage = this.PerfectList.candidateIntegrity;
        // 最后跟新时间
        this.endUpdateDate = this.PerfectList.endUpdateDate;
        // 获取工作经历数组数据
        this.resumeperfectObj = res.result.jobExperienceList;
        for (let i = 0; i < this.resumeperfectObj.length; i++) {
          this.resumeperfectObj[i].starDate = this.$formatUTC(
            this.resumeperfectObj[i].workStartDate
          );
          this.resumeperfectObj[i].endDate = this.$formatUTC(
            this.resumeperfectObj[i].workEndDate
          );
        }
        // 获取教育经验数组数据
        this.schoolExperiences = res.result.educationExperienceList;
        for (let i = 0; i < this.schoolExperiences.length; i++) {
          this.schoolExperiences[i].starDate = this.$formatUTC(
            this.schoolExperiences[i].graduateStartDate
          );
          this.schoolExperiences[i].endDate = this.$formatUTC(
            this.schoolExperiences[i].graduateEndDate
          );
        }
        // 获取项目经验数组数据
        this.ProjectExperience = res.result.projectExperienceList;
        for (let i = 0; i < this.ProjectExperience.length; i++) {
          this.ProjectExperience[i].starDate = this.$formatUTC(
            this.ProjectExperience[i].projectStartDate
          );
          this.ProjectExperience[i].endDate = this.$formatUTC(
            this.ProjectExperience[i].projectEndDate
          );
        }
        // 获取家庭社会成员关系数组数据
        this.relationExperienceList = res.result.relationExperienceList;
        // this.getAvatarIdPath();
      } else {
        return this.$message.error('获取修改页面数据失败！');
      }
    },
    // 职业类别
    async getExpectJobs() {
      const { data: res } = await ExpectJobs();
      let Jobs = res.result.job;
      let newarr = [];
      this.handleData1(Jobs, newarr);
      this.expectJobsOpt = newarr;
    },
    // 级联选择器循环事件
    handleData(data, newarr) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        //如果子级存在,并且有数据
        if (data[i].children && data[i].children.length > 0) {
          //label为级联选择器要展示的值,我这里使用的是name,value为选中的值,我这里使用的是id,根据个人使用场景,按需调试
          newarr.push({ value: data[i].id, label: data[i].code, children: [] });
          this.handleData(data[i].children, newarr[i].children);
        } else {
          //子级不存在时,不用push  children:[],否则级联选择器最后一页会出现空白,很难看
          newarr.push({ value: data[i].id, label: data[i].code });
        }
      }
      return newarr;
    },
    handleData1(data, newarr) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        //如果子级存在,并且有数据
        if (data[i].children && data[i].children.length > 0) {
          //label为级联选择器要展示的值,我这里使用的是name,value为选中的值,我这里使用的是id,根据个人使用场景,按需调试
          newarr.push({ value: data[i].id, label: data[i].name, children: [] });
          this.handleData(data[i].children, newarr[i].children);
        } else {
          //子级不存在时,不用push  children:[],否则级联选择器最后一页会出现空白,很难看
          newarr.push({ value: data[i].id, label: data[i].name });
        }
      }
      return newarr;
    },
    // 职业类别级联选择器事件
    getCascader(e) {
      for (let i = 0; i < e.length; i++) {
        this.jobobjective_editForm.expectJobs = e[e.length - 1];
      }
    },
    // 循环地点名称事件
    getLoop(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place = item.itemText;
          } else if (item.code == code) {
            this.place = item.name;
          } else if (item.value == code) {
            this.place = item.label;
          } else {
            return;
          }
        });
      }
    },
    getLoop1(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place1 = item.itemText;
          } else if (item.code == code) {
            this.place1 = item.name;
          } else if (item.value == code) {
            this.place1 = item.label;
          } else {
            return;
          }
        });
      }
    },
    getLoop2(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place2 = item.itemText;
          } else if (item.code == code) {
            this.place2 = item.name;
          } else if (item.value == code) {
            this.place2 = item.label;
          } else {
            return;
          }
        });
      }
    },
    // 期望薪资事件
    getexpectSalary(e) {
      this.getLoop(e, this.expectSalaryList);
      this.jobobjective_editForm.expectSalary = this.place;
      this.jobobjective_editForm.expectSalaryCode = e;
    },
    // 性别事件
    getSex(e) {
      this.getLoop(e, this.sexList);
      this.basicEditForm.sex = this.place;
      this.basicEditForm.sexCode = e;
    },

    // 求职意愿修改事件
    jobobjectiveEdit() {
      // 其它参数
      this.bean.username = this.PerfectList.username;
      this.bean.age = this.PerfectList.age;
      this.bean.sex = this.PerfectList.sex;
      this.bean.sexCode = this.PerfectList.sexCode;
      this.bean.education = this.PerfectList.education;
      this.bean.educationCode = this.PerfectList.educationcode;
      this.bean.workExp = this.PerfectList.workExp;
      this.bean.phone = this.PerfectList.phone;
      // 籍贯
      this.bean.nativeArea.province = this.PerfectList.nativeGisCoord.province;
      this.bean.nativeArea.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.bean.nativeArea.city = this.PerfectList.nativeGisCoord.city;
      this.bean.nativeArea.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.bean.nativeArea.county = this.PerfectList.nativeGisCoord.county;
      this.bean.nativeArea.countyCode = this.PerfectList.nativeGisCoord.countyCode;
      // 居住地 address
      this.bean.residenceArea.province = this.PerfectList.dWellGisCoord.province;
      this.bean.residenceArea.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.bean.residenceArea.city = this.PerfectList.dWellGisCoord.city;
      this.bean.residenceArea.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.bean.residenceArea.county = this.PerfectList.dWellGisCoord.county;
      this.bean.residenceArea.countyCode = this.PerfectList.dWellGisCoord.countyCode;

      this.bean.selfAssessment = this.PerfectList.selfAssessment;
      this.bean.avatarId = this.PerfectList.avatarid;
      this.bean.resume.fileToken = this.PerfectList.resumeList[0].fileToken;
      this.bean.resume.name = this.PerfectList.resumeList[0].fileName;
      this.bean.resume.length = this.PerfectList.resumeList[0].fileSize;
      this.bean.id = this.candidateId;
      // 求职意愿参数
      this.bean.expectJobs = this.jobobjective_editForm.expectJobs;
      this.bean.expectSalary = this.jobobjective_editForm.expectSalary;
      this.bean.expectSalaryCode = this.jobobjective_editForm.expectSalaryCode;
      this.bean.workArea.province = this.jobobjective_editForm.workAddress.provinceName;
      this.bean.workArea.provinceCode = this.jobobjective_editForm.workAddress.provinceCode;
      this.bean.workArea.city = this.jobobjective_editForm.workAddress.citysName;
      this.bean.workArea.cityCode = this.jobobjective_editForm.workAddress.cityCode;
      this.bean.workArea.county = this.jobobjective_editForm.workAddress.townName;
      this.bean.workArea.countyCode = this.jobobjective_editForm.workAddress.townCode;

      this.$refs.jobobjective_editRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请重新修改求职意向');
        } else {
          let bean = this.bean;
          const { data: res } = await postResumes(bean);
          if (res.code == 1) {
            this.$message.success('修改成功！');
            let candidateId = this.candidateId;
            this.getPerfectList(candidateId);
            this.jobobjectiveShow = false;
          } else {
            return this.$message.error('修改失败！');
          }
        }
      });
      // this.bean.candidateIntegrity = this.PerfectList.candidateIntegrity;
    },
    // 求职意愿表单重置
    jobResetFields() {
      this.jobobjectiveShow = false;
    },
    // 学历
    getEducation(e) {
      this.getLoop(e, this.educations);
      this.basicEditForm.education = this.place;
      this.basicEditForm.educationCode = e;
    },
    // 工作年限事件
    getworkExp(e) {
      this.getLoop(e, this.workExpOptions);
      this.form.workExp = this.place;
    },
    // 基本信息修改事件
    basicEdit() {
      // 其他参数
      this.bean.expectJobs = this.PerfectList.expectJobs;
      this.bean.workArea.province = this.PerfectList.workGisCoord.province;
      this.bean.workArea.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.bean.workArea.city = this.PerfectList.workGisCoord.city;
      this.bean.workArea.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.bean.workArea.county = this.PerfectList.workGisCoord.county;
      this.bean.workArea.countyCode = this.PerfectList.workGisCoord.countyCode;
      this.bean.expectSalary = this.PerfectList.expectSalary;
      this.bean.expectSalaryCode = this.PerfectList.expectSalaryCode;
      this.bean.selfAssessment = this.PerfectList.selfAssessment;
      this.bean.avatarId = this.PerfectList.avatarid;
      this.bean.phone = this.PerfectList.phone;
      this.bean.resume.fileToken = this.PerfectList.resumeList[0].fileToken;
      this.bean.resume.name = this.PerfectList.resumeList[0].fileName;
      this.bean.resume.length = this.PerfectList.resumeList[0].fileSize;

      // 基本信息参数
      this.bean.username = this.basicEditForm.username;
      this.bean.age = this.basicEditForm.age;
      this.bean.sex = this.basicEditForm.sex;
      this.bean.sexCode = this.basicEditForm.sexCode;
      this.bean.education = this.basicEditForm.education;
      this.bean.educationCode = this.basicEditForm.educationCode;
      this.bean.workExp = this.basicEditForm.workExp;
      // 籍贯
      this.bean.nativeArea.province = this.basicEditForm.nativePlace.provinceName;
      this.bean.nativeArea.provinceCode = this.basicEditForm.nativePlace.provinceCode;
      this.bean.nativeArea.city = this.basicEditForm.nativePlace.citysName;
      this.bean.nativeArea.cityCode = this.basicEditForm.nativePlace.cityCode;
      this.bean.nativeArea.county = this.basicEditForm.nativePlace.townName;
      this.bean.nativeArea.countyCode = this.basicEditForm.nativePlace.townCode;
      // 居住地 address
      this.bean.residenceArea.province = this.basicEditForm.address.provinceName;
      this.bean.residenceArea.provinceCode = this.basicEditForm.address.provinceCode;
      this.bean.residenceArea.city = this.basicEditForm.address.citysName;
      this.bean.residenceArea.cityCode = this.basicEditForm.address.cityCode;
      this.bean.residenceArea.county = this.basicEditForm.address.townName;
      this.bean.residenceArea.countyCode = this.basicEditForm.address.townCode;
      this.bean.id = this.candidateId;
      this.bean.candidateIntegrity = this.PerfectList.candidateIntegrity;
      //this.bean.
      this.$refs.basicEditRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('请重新修改基本信息');
        } else {
          let bean = this.bean;
          const { data: res } = await postResumes(bean);
          if (res.code == 1) {
            this.$message.success('修改成功！');
            let candidateId = this.candidateId;
            this.getPerfectList(candidateId);
            this.basicMeaasge = false;
          } else {
            return this.$message.error('修改失败！');
          }
        }
      });
    },
    // 基本信息取消事件
    basicFields() {
      this.basicMeaasge = false;
    },
    // 自我介绍
    async selfAssessmentEdit() {
      // 自我介绍参数
      this.bean.selfAssessment = this.SelfEvaluationForm.selfAssessment;

      // 获取bean其他参数的值
      this.bean.username = this.PerfectList.username;
      this.bean.age = this.PerfectList.age;
      this.bean.sex = this.PerfectList.sex;
      this.bean.sexCode = this.PerfectList.sexCode;
      this.bean.education = this.PerfectList.education;
      this.bean.educationCode = this.PerfectList.educationcode;
      this.bean.workExp = this.PerfectList.workExp;
      // 籍贯
      this.bean.nativeArea.province = this.PerfectList.nativeGisCoord.province;
      this.bean.nativeArea.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.bean.nativeArea.city = this.PerfectList.nativeGisCoord.city;
      this.bean.nativeArea.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.bean.nativeArea.county = this.PerfectList.nativeGisCoord.county;
      this.bean.nativeArea.countyCode = this.PerfectList.nativeGisCoord.countyCode;
      // 居住地 address
      this.bean.residenceArea.province = this.PerfectList.dWellGisCoord.province;
      this.bean.residenceArea.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.bean.residenceArea.city = this.PerfectList.dWellGisCoord.city;
      this.bean.residenceArea.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.bean.residenceArea.county = this.PerfectList.dWellGisCoord.county;
      this.bean.residenceArea.countyCode = this.PerfectList.dWellGisCoord.countyCode;

      this.bean.expectJobs = this.PerfectList.expectJobs;
      this.bean.workArea.province = this.PerfectList.workGisCoord.province;
      this.bean.workArea.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.bean.workArea.city = this.PerfectList.workGisCoord.city;
      this.bean.workArea.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.bean.workArea.county = this.PerfectList.workGisCoord.county;
      this.bean.workArea.countyCode = this.PerfectList.workGisCoord.countyCode;
      this.bean.expectSalary = this.PerfectList.expectSalary;
      this.bean.expectSalaryCode = this.PerfectList.expectSalaryCode;
      this.bean.avatarId = this.PerfectList.avatarid;
      this.bean.phone = this.PerfectList.phone;
      this.bean.resume.fileToken = this.PerfectList.resumeList[0].fileToken;
      this.bean.resume.name = this.PerfectList.resumeList[0].fileName;
      this.bean.resume.length = this.PerfectList.resumeList[0].fileSize;

      this.bean.id = this.candidateId;
      let bean = this.bean;
      const { data: res } = await postResumes(bean);
      if (res.code == 1) {
        this.$message.success('修改成功！');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.SelfEvaluationShow = false;
      } else {
        return this.$message.error('修改失败！');
      }
    },
    // 自我介绍取消事件
    selfAssessmentRes() {
      // this.SelfEvaluationForm.selfAssessment = '';
      this.SelfEvaluationShow = false;
    },
    // 工作经历表单按钮添加事件
    async workExperienceAdd() {
      let param = {};
      param.companyName = this.workExperienceForm.companyName;
      param.jobContent = this.workExperienceForm.jobContent;
      param.positionName = this.workExperienceForm.positionName;
      param.salary = this.workExperienceForm.salary;
      param.workEndDate = this.workExperienceForm.workEndDate[1];
      param.workStartDate = this.workExperienceForm.workEndDate[0];
      let jobLength = this.PerfectList.jobExperienceList.length;
      if (jobLength == 0) {
        this.percentage = this.percentage + 10;
      } else {
      }
      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await job(param);
      if (res.code == 1) {
        this.$message.success('添加工作经历成功！');
        this.getPercentage();
        this.workId = res.result.id;
        this.workExperienceForm.positionName = '';
        this.workExperienceForm.salary = 0;
        this.workExperienceForm.companyName = '';
        this.workExperienceForm.workEndDate = [];
        this.workExperienceForm.jobContent = '';
        this.workexpShowAdd = false;
      } else {
        return this.$message.error('添加工作经历失败！');
      }
    },
    // 工作经历表单按钮修改事件
    async workExperienceEdit(item, index) {
      let param = {};
      param.companyName = this.workExperienceForm.companyName;
      param.jobContent = this.workExperienceForm.jobContent;
      param.positionName = this.workExperienceForm.positionName;
      param.salary = this.workExperienceForm.salary;
      param.workEndDate = this.workExperienceForm.workEndDate[1];
      param.workStartDate = this.workExperienceForm.workEndDate[0];
      let candidateId = this.candidateId;
      param.candidateId = candidateId;

      param.id = item.id;
      const { data: res } = await job(param);
      if (res.code == 1) {
        this.$message.success('修改工作经历成功！');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.workExperienceForm.positionName = '';
        this.workExperienceForm.salary = 0;
        this.workExperienceForm.companyName = '';
        this.workExperienceForm.workEndDate = [];
        this.workExperienceForm.jobContent = '';
        this.jid = index - 100;
      } else {
        return this.$message.error('修改工作经历失败！');
      }
    },
    // 工作经历表单按钮取消事件
    workExperienceRes1(index) {
      this.workExperienceForm.positionName = '';
      this.workExperienceForm.salary = 0;
      this.workExperienceForm.companyName = '';
      this.workExperienceForm.workEndDate = [];
      this.workExperienceForm.jobContent = '';
      this.jid = index - 100;
    },
    workExperienceRes2() {
      this.workExperienceForm.positionName = '';
      this.workExperienceForm.salary = 0;
      this.workExperienceForm.companyName = '';
      this.workExperienceForm.workEndDate = [];
      this.workExperienceForm.jobContent = '';
      this.workexpShowAdd = false;
    },
    // 教育经验表单按钮添加事件
    async schoolExperienceAdd() {
      let param = {};
      param.schoolName = this.schoolExperienceForm.schoolName;
      param.majorName = this.schoolExperienceForm.majorName;
      param.schoolExperience = this.schoolExperienceForm.schoolExperience;
      param.graduateEndDate = this.schoolExperienceForm.graduateStartDate[1];
      param.graduateStartDate = this.schoolExperienceForm.graduateStartDate[0];

      let educationLength = this.PerfectList.educationExperienceList.length;
      if (educationLength == 0) {
        this.percentage = this.percentage + 10;
      } else {
      }
      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await education(param);
      if (res.code == 1) {
        this.$message.success('添加教育经验成功！');
        this.getPercentage();
        this.schoolExperienceForm.schoolName = '';
        this.schoolExperienceForm.majorName = '';
        this.schoolExperienceForm.graduateStartDate = [];
        this.schoolExperienceForm.schoolExperience = '';
        this.shoolShowAdd = false;
      } else {
        return this.$message.error('添加教育经验失败！');
      }
    },
    // 教育经验表单按钮修改事件
    async schoolExperienceEdit(item, index) {
      let param = {};
      param.schoolName = this.schoolExperienceForm.schoolName;
      param.majorName = this.schoolExperienceForm.majorName;
      param.schoolExperience = this.schoolExperienceForm.schoolExperience;
      param.graduateEndDate = this.schoolExperienceForm.graduateStartDate[1];
      param.graduateStartDate = this.schoolExperienceForm.graduateStartDate[0];
      param.id = item.id;

      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await education(param);
      if (res.code == 1) {
        this.$message.success('修改教育经验成功！');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.schoolExperienceForm.schoolName = '';
        this.schoolExperienceForm.majorName = '';
        this.schoolExperienceForm.graduateStartDate = [];
        this.schoolExperienceForm.schoolExperience = '';
      } else {
        return this.$message.error('修改教育经验失败！');
      }
      this.sid = index - 100;
    },
    // 教育经验表单按钮取消事件
    schoolExperienceRes1(index) {
      this.schoolExperienceForm.schoolName = '';
      this.schoolExperienceForm.majorName = '';
      this.schoolExperienceForm.graduateStartDate = [];
      this.schoolExperienceForm.schoolExperience = '';
      this.sid = index - 100;
    },
    schoolExperienceRes2() {
      this.schoolExperienceForm.schoolName = '';
      this.schoolExperienceForm.majorName = '';
      this.schoolExperienceForm.graduateStartDate = [];
      this.schoolExperienceForm.schoolExperience = '';
      this.shoolShowAdd = false;
    },
    // 项目经验表单按钮添加事件
    async ProjectExperienceAdd() {
      let param = {};
      param.projectName = this.ProjectExperienceForm.projectName;
      param.positionName = this.ProjectExperienceForm.positionName;
      param.projectAchievement = this.ProjectExperienceForm.projectAchievement;
      param.projectIntroduce = this.ProjectExperienceForm.projectIntroduce;
      param.projectEndDate = this.ProjectExperienceForm.projectStartDate[1];
      param.projectStartDate = this.ProjectExperienceForm.projectStartDate[0];

      let projectLength = this.PerfectList.projectExperienceList.length;
      if (projectLength == 0) {
        this.percentage = this.percentage + 10;
      } else {
      }
      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await projectAdd(param);
      if (res.code == 1) {
        this.$message.success('添加项目经验成功！');

        this.getPercentage();
        this.ProjectExperienceForm.projectName = '';
        this.ProjectExperienceForm.positionName = '';
        this.ProjectExperienceForm.projectAchievement = '';
        this.ProjectExperienceForm.projectIntroduce = '';
        this.ProjectExperienceForm.projectStartDate = [];
        this.ProjectShowAdd = false;
      } else {
        return this.$message.error('添加项目经验失败！');
      }
    },
    // 项目经验表单按钮修改事件
    async ProjectExperienceEdit(item, index) {
      let param = {};
      param.projectName = this.ProjectExperienceForm.projectName;
      param.positionName = this.ProjectExperienceForm.positionName;
      param.projectAchievement = this.ProjectExperienceForm.projectAchievement;
      param.projectIntroduce = this.ProjectExperienceForm.projectIntroduce;
      param.projectEndDate = this.ProjectExperienceForm.projectStartDate[1];
      param.projectStartDate = this.ProjectExperienceForm.projectStartDate[0];
      param.id = item.id;

      let candidateId = this.candidateId;
      param.candidateId = candidateId;
      const { data: res } = await projectAdd(param);
      if (res.code == 1) {
        this.$message.success('修改项目经验成功！');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.ProjectExperienceForm.projectName = '';
        this.ProjectExperienceForm.positionName = '';
        this.ProjectExperienceForm.projectAchievement = '';
        this.ProjectExperienceForm.projectIntroduce = '';
        this.ProjectExperienceForm.projectStartDate = [];
      } else {
        return this.$message.error('修改项目经验失败！');
      }
      this.pid = index - 100;
    },
    // 项目经验表单按钮取消事件
    ProjectExperience1(index) {
      this.ProjectExperienceForm.projectName = '';
      this.ProjectExperienceForm.positionName = '';
      this.ProjectExperienceForm.projectStartDate = [];
      this.ProjectExperienceForm.projectIntroduce = '';
      this.ProjectExperienceForm.projectAchievement = '';
      this.pid = index - 100;
    },
    ProjectExperience2() {
      this.ProjectExperienceForm.projectName = '';
      this.ProjectExperienceForm.positionName = '';
      this.ProjectExperienceForm.projectStartDate = [];
      this.ProjectExperienceForm.projectIntroduce = '';
      this.ProjectExperienceForm.projectAchievement = '';
      this.ProjectShowAdd = false;
    },
    // 上传简历成功时的钩子
    async resumeSuccess(response, file, fileList) {
      // 获取bean其他参数的值
      this.bean.username = this.PerfectList.username;
      this.bean.age = this.PerfectList.age;
      this.bean.sex = this.PerfectList.sex;
      this.bean.sexCode = this.PerfectList.sexCode;
      this.bean.education = this.PerfectList.education;
      this.bean.educationCode = this.PerfectList.educationcode;
      this.bean.workExp = this.PerfectList.workExp;
      // 籍贯
      this.bean.nativeArea.province = this.PerfectList.nativeGisCoord.province;
      this.bean.nativeArea.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.bean.nativeArea.city = this.PerfectList.nativeGisCoord.city;
      this.bean.nativeArea.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.bean.nativeArea.county = this.PerfectList.nativeGisCoord.county;
      this.bean.nativeArea.countyCode = this.PerfectList.nativeGisCoord.countyCode;
      // 居住地 address
      this.bean.residenceArea.province = this.PerfectList.dWellGisCoord.province;
      this.bean.residenceArea.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.bean.residenceArea.city = this.PerfectList.dWellGisCoord.city;
      this.bean.residenceArea.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.bean.residenceArea.county = this.PerfectList.dWellGisCoord.county;
      this.bean.residenceArea.countyCode = this.PerfectList.dWellGisCoord.countyCode;

      this.bean.expectJobs = this.PerfectList.expectJobs;
      this.bean.workArea.province = this.PerfectList.workGisCoord.province;
      this.bean.workArea.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.bean.workArea.city = this.PerfectList.workGisCoord.city;
      this.bean.workArea.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.bean.workArea.county = this.PerfectList.workGisCoord.county;
      this.bean.workArea.countyCode = this.PerfectList.workGisCoord.countyCode;
      this.bean.expectSalary = this.PerfectList.expectSalary;
      this.bean.expectSalaryCode = this.PerfectList.expectSalaryCode;
      this.bean.avatarId = this.PerfectList.avatarid;
      this.bean.phone = this.PerfectList.phone;
      this.bean.resume.fileToken = response.result.fileToken;
      this.bean.resume.name = response.result.name;
      this.bean.resume.length = response.result.length;
      // 自我介绍参数
      this.bean.selfAssessment = this.PerfectList.selfAssessment;
      // 进度条参数
      this.bean.candidateIntegrity = this.PerfectList.candidateIntegrity;

      this.bean.id = this.candidateId;
      let bean = this.bean;
      const { data: res } = await postResumes(bean);
      if (res.code == 1) {
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
      } else {
        return;
      }
    },
    // 上传简历失败时
    resumeError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传简历失败');
    },
    // 求职过滤条件
    async getCandidatefilter() {
      const { data: res } = await ExpectJobs();
      if (res.code == 1) {
        let expectSalaryList = res.result.sals;
        this.expectSalaryList = expectSalaryList.slice(
          1,
          expectSalaryList.length - 1
        );
        let educations = res.result.edus;
        this.educations = educations.slice(1, educations.length - 1);
        this.sexList = res.result.sexs;
      } else {
        this.$message.error('过滤条件获取失败！');
      }
    },
    // 上传头像成功时的事件
    async handleAvatarSuccess(response, file) {
      // this.imageUrl = file.url;
      let avatarId = response.result.fileToken;
      // 上传头像的id
      this.bean.avatarId = avatarId;

      // 获取bean其他参数的值
      this.bean.username = this.PerfectList.username;
      this.bean.age = this.PerfectList.age;
      this.bean.sex = this.PerfectList.sex;
      this.bean.sexCode = this.PerfectList.sexCode;
      this.bean.education = this.PerfectList.education;
      this.bean.educationCode = this.PerfectList.educationcode;
      this.bean.workExp = this.PerfectList.workExp;
      // 籍贯
      this.bean.nativeArea.province = this.PerfectList.nativeGisCoord.province;
      this.bean.nativeArea.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.bean.nativeArea.city = this.PerfectList.nativeGisCoord.city;
      this.bean.nativeArea.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.bean.nativeArea.county = this.PerfectList.nativeGisCoord.county;
      this.bean.nativeArea.countyCode = this.PerfectList.nativeGisCoord.countyCode;
      // 居住地 address
      this.bean.residenceArea.province = this.PerfectList.dWellGisCoord.province;
      this.bean.residenceArea.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.bean.residenceArea.city = this.PerfectList.dWellGisCoord.city;
      this.bean.residenceArea.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.bean.residenceArea.county = this.PerfectList.dWellGisCoord.county;
      this.bean.residenceArea.countyCode = this.PerfectList.dWellGisCoord.countyCode;

      this.bean.expectJobs = this.PerfectList.expectJobs;
      this.bean.workArea.province = this.PerfectList.workGisCoord.province;
      this.bean.workArea.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.bean.workArea.city = this.PerfectList.workGisCoord.city;
      this.bean.workArea.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.bean.workArea.county = this.PerfectList.workGisCoord.county;
      this.bean.workArea.countyCode = this.PerfectList.workGisCoord.countyCode;
      this.bean.expectSalary = this.PerfectList.expectSalary;
      this.bean.expectSalaryCode = this.PerfectList.expectSalaryCode;

      this.bean.selfAssessment = this.PerfectList.selfAssessment;
      this.bean.phone = this.PerfectList.phone;
      this.bean.resume.fileToken = this.PerfectList.resumeList[0].fileToken;
      this.bean.resume.name = this.PerfectList.resumeList[0].fileName;
      this.bean.resume.length = this.PerfectList.resumeList[0].fileSize;

      this.bean.id = this.candidateId;
      let bean = this.bean;
      const { data: res } = await postResumes(bean);
      if (res.code == 1) {
        this.$message.success('修改成功！');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.SelfEvaluationShow = false;
      } else {
        return this.$message.error('修改失败！');
      }
    },
    // 进度条数据保存percentage
    async getPercentage() {
      // 进度条数据
      this.bean.selfAssessment = this.PerfectList.selfAssessment;

      // 获取bean其他参数的值
      this.bean.username = this.PerfectList.username;
      this.bean.age = this.PerfectList.age;
      this.bean.sex = this.PerfectList.sex;
      this.bean.sexCode = this.PerfectList.sexCode;
      this.bean.education = this.PerfectList.education;
      this.bean.educationCode = this.PerfectList.educationcode;
      this.bean.workExp = this.PerfectList.workExp;
      // 籍贯
      this.bean.nativeArea.province = this.PerfectList.nativeGisCoord.province;
      this.bean.nativeArea.provinceCode = this.PerfectList.nativeGisCoord.provinceCode;
      this.bean.nativeArea.city = this.PerfectList.nativeGisCoord.city;
      this.bean.nativeArea.cityCode = this.PerfectList.nativeGisCoord.cityCode;
      this.bean.nativeArea.county = this.PerfectList.nativeGisCoord.county;
      this.bean.nativeArea.countyCode = this.PerfectList.nativeGisCoord.countyCode;
      // 居住地 address
      this.bean.residenceArea.province = this.PerfectList.dWellGisCoord.province;
      this.bean.residenceArea.provinceCode = this.PerfectList.dWellGisCoord.provinceCode;
      this.bean.residenceArea.city = this.PerfectList.dWellGisCoord.city;
      this.bean.residenceArea.cityCode = this.PerfectList.dWellGisCoord.cityCode;
      this.bean.residenceArea.county = this.PerfectList.dWellGisCoord.county;
      this.bean.residenceArea.countyCode = this.PerfectList.dWellGisCoord.countyCode;

      this.bean.expectJobs = this.PerfectList.expectJobs;
      this.bean.workArea.province = this.PerfectList.workGisCoord.province;
      this.bean.workArea.provinceCode = this.PerfectList.workGisCoord.provinceCode;
      this.bean.workArea.city = this.PerfectList.workGisCoord.city;
      this.bean.workArea.cityCode = this.PerfectList.workGisCoord.cityCode;
      this.bean.workArea.county = this.PerfectList.workGisCoord.county;
      this.bean.workArea.countyCode = this.PerfectList.workGisCoord.countyCode;
      this.bean.expectSalary = this.PerfectList.expectSalary;
      this.bean.expectSalaryCode = this.PerfectList.expectSalaryCode;
      this.bean.avatarId = this.PerfectList.avatarid;
      this.bean.phone = this.PerfectList.phone;
      this.bean.resume.fileToken = this.PerfectList.resumeList[0].fileToken;
      this.bean.resume.name = this.PerfectList.resumeList[0].fileName;
      this.bean.resume.length = this.PerfectList.resumeList[0].fileSize;

      // 进度条参数
      this.bean.candidateIntegrity = this.percentage;

      this.bean.id = this.candidateId;
      let bean = this.bean;
      const { data: res } = await postResumes(bean);
      if (res.code == 1) {
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
      } else {
        return;
      }
    },
    // 家庭社会成员关系添加表单事件
    async familySocialRelationsAdd() {
      let param = {};
      param.relation = this.familySocialRelationsForm.relation;
      param.name = this.familySocialRelationsForm.name;
      param.age = this.familySocialRelationsForm.age;
      param.policitalStatus = this.familySocialRelationsForm.policitalStatus;
      param.workUnit = this.familySocialRelationsForm.workUnit;
      param.workPost = this.familySocialRelationsForm.workPost;
      param.phone = this.familySocialRelationsForm.phone;
      param.candidateId = this.candidateId;
      const { data: res } = await familyMember(param);
      console.log(res);
      if (res.code == 1) {
        this.$message.success('添加成功');
        this.getPercentage();
        this.familySocialRelationsForm.relation = '';
        this.familySocialRelationsForm.name = '';
        this.familySocialRelationsForm.age = '';
        this.familySocialRelationsForm.policitalStatus = '';
        this.familySocialRelationsForm.workUnit = '';
        this.familySocialRelationsForm.workPost = '';
        this.familySocialRelationsForm.phone = '';
        this.familySocialRelationsShow = false;
      } else {
        return this.$message.success('添加失败');
      }
    },
    // 家庭社会成员关系修改表单事件
    async familySocialRelationsAdd1(item, index) {
      let param = {};
      param.relation = this.familySocialRelationsForm.relation;
      param.name = this.familySocialRelationsForm.name;
      param.age = this.familySocialRelationsForm.age;
      param.policitalStatus = this.familySocialRelationsForm.policitalStatus;
      param.workUnit = this.familySocialRelationsForm.workUnit;
      param.workPost = this.familySocialRelationsForm.workPost;
      param.phone = this.familySocialRelationsForm.phone;
      param.candidateId = this.candidateId;
      param.id = item.id;
      const { data: res } = await familyMember(param);
      console.log(res);
      if (res.code == 1) {
        this.$message.success('修改成功');
        let candidateId = this.candidateId;
        this.getPerfectList(candidateId);
        this.familySocialRelationsForm.relation = '';
        this.familySocialRelationsForm.name = '';
        this.familySocialRelationsForm.age = '';
        this.familySocialRelationsForm.policitalStatus = '';
        this.familySocialRelationsForm.workUnit = '';
        this.familySocialRelationsForm.workPost = '';
        this.familySocialRelationsForm.phone = '';
      } else {
        return this.$message.success('修改失败');
      }
      this.fid = index - 100;
    },
    // 家庭社会成员关系表单按钮取消事件
    familyExperience() {
      this.familySocialRelationsForm.relation = '';
      this.familySocialRelationsForm.name = '';
      this.familySocialRelationsForm.age = '';
      this.familySocialRelationsForm.policitalStatus = '';
      this.familySocialRelationsForm.workUnit = '';
      this.familySocialRelationsForm.workPost = '';
      this.familySocialRelationsForm.phone = '';
      this.familySocialRelationsShow = false;
    },
    familyExperience1(index) {
      this.familySocialRelationsForm.relation = '';
      this.familySocialRelationsForm.name = '';
      this.familySocialRelationsForm.age = '';
      this.familySocialRelationsForm.policitalStatus = '';
      this.familySocialRelationsForm.workUnit = '';
      this.familySocialRelationsForm.workPost = '';
      this.familySocialRelationsForm.phone = '';
      this.fid = index - 100;
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: resumeicon;
  src: url(../../assets/fonts/resumeicon.eot);
  src: url(../../assets/fonts/resumeicon.eot?#iefix) format('embedded-opentype'),
    url(../../assets/fonts/resumeicon.woff) format('woff'),
    url(../../assets/fonts/resumeicon.ttf) format('truetype'),
    url(../../assets/fonts/resumeicon.svg) format('svg');
}
/* 公共样式开始 */
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 880px;
  margin-right: 60px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .titleL {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  font-weight: 400;
  border-bottom: 1px solid #e0e0e0;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}
.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/* 公共样式结束 */
.functionUnit {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.annex {
  width: 30%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  padding: 16px 18px;
  box-sizing: border-box;
  margin-left: 10px;
}
.annex .upload-title {
  height: 21px;
  line-height: 21px;
  font-size: 16px;
  color: #666;
  text-align: left;
}
.annex .upload-btn {
  width: 174px;
  height: 40px;
  line-height: 40px;
  background: #ff552e;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  margin: 14px 0 10px 0;
  cursor: pointer;
  text-align: center;
}
.annex .upload-explain {
  line-height: 16px;
  font-size: 12px;
  color: #aaa;
}
.completion {
  padding: 20px;
  width: 30%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  padding: 16px 18px;
  box-sizing: border-box;
}
.completion .titles {
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 16px;
  color: #666;
  text-align: left;
  margin-bottom: 5px;
}
.completion .titles span {
  color: #ff552d;
}
.completion .desc {
  margin-top: 10px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 12px;
  color: #aaa;
  text-align: left;
}
.resumeTabs {
  margin-top: 30px;
}
.updatedate {
  padding: 0 30px;
  height: 60px;
  text-align: left;
  line-height: 60px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #999;
}
.el-main {
  border: 1px solid #eee;
  border-bottom-color: transparent;
  margin-left: 20px;
  padding: 0;
}

.infos {
  width: 100%;
  position: relative;
  padding: 26px 30px 50px;
  overflow: hidden;
}
.infos .title {
  float: left;
}
.infos .title h2 {
  display: inline-block;
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 20px;
  color: #333;
  line-height: 32px;
  font-weight: 400;
}
.infos .title h4 {
  display: inline-block;
  margin-left: 5px;
  color: #666;
  font-weight: 400;
}
.infos i {
  margin-right: 4px;
  font-size: 14px;
  color: #999;
  font-family: resumeicon !important;
  font-size: 12px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.infos .btn {
  margin-right: 70px;
}
.infos .btn div {
  margin-right: 10px;
  margin-top: 15px;
  float: right;
  width: 56px;
  height: 24px;
  background: #ff552d;
  border-radius: 2px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}
.infos .btn i {
  color: #fff !important;
}
.infos .commonaddbtn {
  margin: 0 auto;
  padding: 0 5px;
  width: 140px;
  height: 38px;
  background: #f8f9fb;
  border-radius: 2px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #666;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
  margin-top: 50px;
}
.infos .commonaddbtn i {
  font-size: 14px;
  color: #ccc;
  padding-right: 5px;
}

.basic {
  border-bottom: 1px solid #eee;
  display: flex;
}
.basic .infos {
  width: 70%;
}
.basic .infos .title h2 {
  font-size: 24px;
}
.basic .infos ul {
  margin-top: 14px;
}
.basic .infos li {
  /* float: left; */
  width: 260px;
  height: 32px;
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #666;
  text-align: left;
}
.basic .head {
  width: 110px;
  height: 110px;
  position: relative;
  border-radius: 50%;
  margin-left: -25px;
  margin-top: 33px;
}
.basic .head img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.required {
  font-family: Tahoma;
  font-size: 14px;
  color: red;
  line-height: 14px;
}
.edit {
  background: #f8f9fb;
  padding: 30px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}
.edit .title {
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 20px;
  color: #333;
  line-height: 28px;
}
.edit .el-form {
  margin-top: 30px;
}
.edit .el-form .controly {
  width: 200px;
}
.edit .el-form .control {
  width: 300px;
}
.edit .el-form .controlx {
  width: 483px;
}
.edit .el-form .controls {
  width: 86%;
}
.controls .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.edit .el-button {
  min-width: 80px;
}
.edit .el-button--primary {
  background-color: #ff552d;
  border: 1px solid #ff552d;
}

.jobobjective .content {
  margin-top: 50px;
}

.jobobjective .content span {
  font-family: PingFangSC-Regular, Microsoft YaHei;
  font-size: 14px;
  color: #333;
  text-align: left;
}
.jobobjective .content i {
  font-size: 12px;
  color: #cdcdcd;
  padding: 0 10px;
}

.workexp .content {
  padding-top: 14px;
}
.workexp .content dt {
  float: left;
  font-size: 14px;
  color: #999;
  line-height: 20px;
  vertical-align: top;
  width: 80px;
}
.workexp .content dd {
  float: left;
  font-size: 14px;
  color: #666;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
}
.workexp .content .com {
  padding-top: 12px;
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-weight: 700;
  font-size: 16px;
  color: #333;
  line-height: 28px;
  width: auto;
}
.workexp .content dd.com {
  font-size: 14px;
  color: #999;
  line-height: 20px;
  margin: 6px 0 0 5px;
}
.school {
  margin-top: 35px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}
.schools {
  margin-top: 40px;
  width: 83%;
}
.worke {
  margin-top: 35px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}
.Project {
  margin-top: 35px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}
.el-main {
  border: 1px solid #eee;
  border-bottom-color: transparent;
  padding: 0;
}
.aside {
  overflow: auto;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  top: 0;
}
.basic .head >>> .el-upload--picture-card {
  background-color: #fbfdff;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  vertical-align: top;
}
.basic .head >>> .el-upload__tip {
  font-size: 12px;
  color: #606266;
  width: 100px;
  margin-left: 26px;
}
</style>